<template>
  <v-row justify="center">
    <v-dialog
      v-model="detDialog"
      width="1200px"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          v-on="on"
          small
          @click="getData"
        >
        <v-icon>mdi-file-document-multiple</v-icon>
        </v-btn>
      </template>

    <v-card>
        <v-card-title>
          <span class="text-h5" id="dialog_title">DET List</span>
        </v-card-title>
        <v-card-text>
            <v-container>

            <v-row class="mt-5">
                <v-col cols="12">
                    <v-card elevation="1" class="ph-nonex-datatable-container">
                        <v-data-table
                            :headers="headers"
                            :items="detList"
                            :calculate-widths="true"
                            class="ph-nonex-datatable"
                            >
                            <template v-slot:[`item.created_at`]="{item}">
                                {{formatDate(item.created_at)}}
                            </template>
                            <template v-slot:[`item.action`]="{item}">
                                <div>

                                    <!-- Encoder Buttons -->
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.det_status === 'New'"
                                        text
                                        color="primary"
                                        @click="updateDet(item)"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.det_status === 'DET Encoder (Returned to Account Manager)'"
                                        text
                                        color="primary"
                                        @click="updateDet(item)"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.det_status === 'DET Compliance (Returned to Account Manager)'"
                                        text
                                        color="primary"
                                        @click="updateDet(item)"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.det_status === 'DET Encoder Pending'"
                                        text
                                        color="primary"
                                        @click="viewDet(item)"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                    </v-btn>
                                    
                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.det_status === 'DET Compliance (Returned to DET Encoder)'"
                                        text
                                        color="primary"
                                        @click="viewDet(item)"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.det_status === 'DET Compliance Approval Pending'"
                                        text
                                        color="primary"
                                        @click="viewDet(item)"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.det_status === 'DET Compliance (Returned)'"
                                        text
                                        color="primary"
                                        @click="viewDet(item)"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.det_status === 'DET Approved'"
                                        text
                                        color="primary"
                                        @click="viewDet(item)"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && (item.det_status == 'Sent to BSP FSD9'|| item.det_status == 'Notice of Return Receipt (FSD9)'|| item.det_status == 'Sent to BSP FSS Mail'|| item.det_status == 'Notice of Return Receipt (FSS Mail)'|| item.det_status == 'OPRN Paid by Account Manager'|| item.det_status == 'Currently in process on BSP'|| item.det_status == 'Accreditation Letter from BSP')"
                                        text
                                        color="primary"
                                        @click="viewDet(item)"
                                        >
                                        <v-icon>mdi-eye-outline</v-icon>
                                    </v-btn>

                                    <v-btn
                                        v-if="currUser.user_role === 'encoder' && item.det_status == 'OPRN Received by Compliance'"
                                        text
                                        color="primary"
                                        @click="viewDet(item)"
                                        >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                  <!-- /Encoder Buttons -->

                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            </v-container>
    </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined color="primary"
            text
            @click="cancel()"
            :disabled="isLoading"
          >
            Cancel
          </v-btn>
            <v-btn
            color="success"
            @click="addDet"
            :disabled="isLoading"
            >
            Add DET
        </v-btn>
        
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseISO, format } from 'date-fns'

// import _ from 'lodash';

  export default {
    props: ["item"],
    data () {
      return {
          detDialog: false,
          fab: false,
          detList: [],
          headers: [
              {
                  text: 'DET Type',
                  sortable: true,
                  value: 'det_type'
              },
              {
                  text: 'Date Created',
                  sortable: true,
                  value: 'created_at'
              },
              {
                  text: 'DET Status',
                  sortable: true,
                  value: 'det_status'
              },
              {
                  text: 'Action',
                  sortable: false,
                  value: 'action',
                  align: 'center'
              }
          ]
      }
    },
    computed: {
    ...mapGetters({
            currUser: 'auth/currUser',
            isLoading: 'app/isLoading'
        }),
    },
    methods:{
        async updateDet(item){

          const payload = {
            id : item.sales_prospects_id,
            finalPayload: {
              bsp_id: item.id
            }
          }

          const res = await this.$store.dispatch('bsp/doGetDetDetails', {payload})
          if (res.status === 200){
            this.$store.commit('bsp/SET_BSP_ID', item.id)
            this.$store.commit('bsp/SET_DET_DETAILS', res.data.result)
            this.$router.push('/det-form/'+ item.sales_prospects_id)
          }

        },
        async viewDet(item){

          const payload = {
            id : item.sales_prospects_id,
            finalPayload: {
              bsp_id: item.id
            }
          }

          const res = await this.$store.dispatch('bsp/doGetDetDetails', {payload})
          if (res.status === 200){
            this.$store.commit('bsp/SET_BSP_ID', item.id)
            this.$store.commit('bsp/SET_DET_DETAILS', res.data.result)
            this.$router.push('/det-form-view/'+ item.sales_prospects_id)
          }

        },
        async addDet(){
            const res = await this.$store.dispatch('bsp/doCreateBsp', this.item)
            if(res.status === 200){
                this.$store.commit('bsp/SET_BSP_ID', res.data.result.id)
                this.$router.push('/det-form/'+this.item);
            }
        },
        async getData(){
            const res = await this.$store.dispatch('bsp/doGetDetBySp', this.item)
            console.log(res.data.result)
            this.detList = res.data.result
        },
        cancel(){
            this.detDialog = false
            this.$store.commit('bsp/UNSET_BSP_ID')
        },
        formatDate (date) {
            return format(parseISO(date), 'yyyy-MM-dd')
        },
    },
  }
</script>

<style scoped>
    #dialog_title{
        color: rgb(20, 20, 137);
        font-weight: 600;
    }
</style>